<template>
  <div class="userInfo">
    <div class="bg"></div>
    <div class="sign-bg">
      <div class="info-top">
        <div class="top-img flex between center">
          <img class="pointer" src="@/assets/sign-e.png" @click="toHome()" />
          <div class="pointer">
            <span @click="outLogin">退出登陆</span> |
             <span @click="toUrl()">返回</span>
          </div>
        </div>
        <div class="top-info flex between">
          <div class="flex top-info-head">
            <img src="@/assets/head.png" v-if="info.avatar == ''" />
            <img :src="avatar" v-else />
            <div>
              <div>{{ info.truename }}</div>
              <div>{{ info.id_number }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-type1">
        <div class="userList">
          <div class="list-tit">准考证</div>
          <table class="table" v-if="applyMyList.length != 0">
            <tr class="trBg">
              <td>准考证类型</td>
              <td>准考证号</td>
              <td>考试时间</td>
              <td>考试地点</td>
              <td>考试科目</td>
              <!-- <td style="width: 15%" v-if="item.exam.exam_time_desc != ''">
                考试时间描述
              </td> -->
              <td>操作</td>
            </tr>
            <tr v-for="(item, index) in applyMyList" :key="index">
              <td>{{ item.exam_type_text }}</td>
              <td>{{ item.examinee_number }}</td>
              <td>{{ item.exam_time_desc }}</td>
              <td>{{ item.exam_address }}</td>
              <td>{{ item.exam_subject }}</td>
              <td>
                <div class="tdbtn pointer" @click="toPrint(item.id)">打印</div>
              </td>
            </tr>
          </table>
          <div class="zan" v-else>暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getFormat from "@/until/getTime.js";
import { userDetail, registrationList } from "@/api/apiData";
import "babel-polyfill"; // es6 shim
// import myUpload from "vue-image-crop-upload";
export default {
  data() {
    return {
      type: 1,
      roterType: "",
      avatar: "",
      name: "",
      radio: "1",
      options1: [
        {
          value: "选项1",
          label: "身份证",
        },
      ],
      value1: "", //证件类型
      options2: [
        {
          value: "选项1",
          label: "汉族",
        },
        {
          value: "选项2",
          label: "满族",
        },
      ],
      value2: "", //民族
      value3: "", //日期
      imgDataUrl: "",
      show: false,
      size: 2.1,
      imageUrl: "",
      urlUpdate: "",
      token: "",
      info: "",
      id_number: "",
      examInfo: "",
      nation_id: "",
      applyMyList: [],
      lookInfo: "",
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "80px",
      zpyq: "",
      starSta: "",
      endSta: "",
    };
  },
  mounted() {
    this.$bus.emit("navS", 0);
    this.getInfo();
    this.getApply();
  },
  methods: {
    // 记录列表
    getApply() {
      let id = this.$route.query.id;
      registrationList({ exam_apply_id: id }).then((res) => {
        this.applyMyList = res.data;
      });
    },
    // 打印准考证
    toPrint(id) {
      this.$router.push({
        path: "/printInfo",
        query: {
          id: id,
        },
      });
    },
    toHome(){
      this.$router.push('/index')
    },
    // 获取用户详情
    getInfo() {
      userDetail().then((res) => {
        if (res.code == 1) {
          this.info = res.data;
          this.name = res.data.truename;
          this.radio = res.data.sex;
          this.value3 = res.data.birthday;
          this.value1 = this.options1[0].label;
          if (res.data.nation != null) {
            this.value2 = res.data.nation.text;
            this.nation_id = res.data.nation.value;
          }
          if (res.data.avatar != "") {
            let url = "/" + res.data.avatar.split("/").slice(3).join("/");
            this.imgDataUrl = "https://gxrcexam.nccloudmedia.com" + url;
            this.imgDataPath = url;
            this.avatar = "https://gxrcexam.nccloudmedia.com" + url;
          }
          this.id_number = res.data.id_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toUrl() {
      this.$router.go(-1);
    },
    // 退出登陆
    outLogin() {
      this.$confirm("确定要退出登陆吗~", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("token");
          this.$router.push("/signLogin");
        })
        .catch(() => {});
    },
    change(e) {
      this.nation_id = e;
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f2f5f8;
  z-index: -1;
}
.sign-bg {
  .info-top {
    background: white;
    box-shadow: 1px 1px 11px 1px #dfdfdf;
    .top-img {
      width: 60%;
      margin: 0 auto;
      border-bottom: solid 1px #d4d4d4;
      padding: 20px 0;
      img {
        width: 55%;
      }
      div {
        color: #0089e1;
        font-size: 16px;
      }
    }
    .top-info {
      width: 60%;
      margin: 0 auto;
      align-items: center;
      .top-info-head {
        align-items: center;
        line-height: 2;
        font-size: 14px;
        img {
          width: 77px;
          margin-right: 20px;
          border: solid 1px #14a1fd;
        }
      }
      .top-info-nav {
        color: #676f73;
        font-size: 18px;
        align-items: center;
        width: 20%;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .top-info-nav-a {
        color: #0089e1;
      }
    }
  }
  .info-type1 {
    width: 60%;
    margin: 0 auto;
    .userList {
      background: white;
      padding: 20px;
      border-radius: 10px;
      margin-top: 44px;
      .list-tit {
        font-size: 18px;
        color: #333333;
      }
      .table {
        width: 100%;
        text-align: center;
        border-bottom: solid 1px #e6e6e6;
        margin-top: 20px;
        font-size: 13px;
        font-weight: bold;
        td {
          padding: 15px 0;
          width: 130px;
        }
        .trBg {
          background: #e5f5ff;
          color: #676f73;
          font-size: 15px;
        }
        .tdbtn {
          background: #1baefd;
          color: white;
          width: 90px;
          margin: 0 auto;
          height: 34px;
          line-height: 34px;
          border-radius: 5px;
        }
      }
    }
  }
  .info-type2 {
    background: white;
    margin-top: 20px;
    .type2-tit {
      color: #0089e1;
      font-size: 18px;
      padding: 12px 44px;
      border-bottom: solid 1px #e6e6e6;
    }
    .type2-mian {
      width: 70%;
      margin: 0 auto;
      .main-left {
        width: 50%;
        text-align: center;
        margin-top: 20px;
        .up {
          width: 80px;
          height: 80px;
          position: relative;
          top: 50%;
          margin-top: -40px;
          border: dashed 1px #dedede;
        }
        .img-tips {
          width: 126px;
          margin: 10px auto;
          font-size: 14px;
        }
        .input-item {
          align-items: center;
          font-size: 18px;
          margin: 20px 0;
          .input-tit {
            width: 20%;
            text-align: right;
            margin-right: 30px;
          }
        }
        .sub-btn {
          background: #1baefd;
          font-size: 14px;
          width: 90px;
          line-height: 34px;
          margin: 40px auto 30px;
          color: white;
          border-radius: 5px;
        }
      }
      .main-right {
        width: 45%;
        div:nth-child(1) {
          font-size: 18px;
          line-height: 4;
        }
        div:nth-child(2) {
          font-size: 14px;
          color: #afafaf;
        }
      }
    }
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 110px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: auto;
    margin: 0 auto;
    box-shadow: 0 3px 3px #e1d9d9;
    border: dashed 1px #707070;
    display: block;
  }
}
.info-item {
  font-size: 16px;
  margin-bottom: 15px;
  div:nth-child(1) {
    text-align: right;
    width: 80px;
  }
}
.status2 {
  color: #0089e1;
}
</style>